const messages = {
  title: 'Reserva tu lugar',
  description: 'Cita de diagnóstico y escaneo 3D',
  acceptTerms: 'Al continuar acepto los Términos y condiciones y Aviso de privacidad',
  returnPolicy: 'Política de devolución',
  returnPolicyText: 'En caso de solicitarlo, solo podremos reembolsarte el valor de tu cita si acudes a consulta el día y a la hora de tu primer agendamiento.',
  checkReturnPolicy: 'Consulta política de devolución',
  priceDetail: 'Detalles del precio',
  appointmentCost: 'Costo de cita',
  promoDiscount: 'Descuento promo',
  total: 'Total ({currency})',
  back: 'cambiar fecha y hora',
  buttonText: 'Confirmar cita',
  confirmAppointment: 'Confirmar cita',
  confirmAppointmentFree: 'Confirmar cita gratis',
  payAppointment: 'Pagar {price}',
  freeAppointment: 'Agendar cita gratis',
  rescheduleAppointment: 'Reagendar cita',
  error: 'Hubo un error al crear la cita, intenta más tarde',
  welcome: '{Name}, la sonrisa de tus sueños esta cerca',
  preloadSteps: [
    'Revisando fecha y horario',
    'Fecha y horario confirmada',
    'Agendando cita',
    'Cita agendada',
    'Programando recordatorios',
    'Recordatorios programados',
    'Generando página con los detalles de la cita'
  ]
}

export default messages
