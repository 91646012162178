import React from 'react'

import styles from './FooterLegal.module.css'
import I18n from '../../i18n'

/**
 * Legal links inside the footer
 *
 * @param {object} props React Props
 * @returns {React.ReactElement} React Element
 */
const FooterLegal = ({ country }) => {
  const i18n = new I18n(country)

  return (
    <section className={styles.background}>
      <div className={styles.container}>
        <p className={styles.copyright}>
          &copy;
          {' '}
          {new Date().getFullYear()}
          {' '}
          Moons
        </p>
        <div className={styles.legal}>
          <a href={i18n.msg.legal.terms} target="_blank" rel="noopener noreferrer">
            Términos y condiciones
          </a>
          <a href={i18n.msg.legal.privacy} target="_blank" rel="noopener noreferrer">
            Aviso de privacidad
          </a>
        </div>
      </div>
    </section>
  )
}

export default FooterLegal
