/* eslint-disable camelcase */
/* eslint-disable max-lines */
/* eslint-disable complexity */
import React from 'react'
import moment from 'moment'
import 'moment/locale/es'
import styles from './ChooseDates.module.css'
import messages from './ChooseDates.messages'
import apiAcuity from '../../Api/Acuity'
import Utils from '../../Utils/utils'
import implant from '../../Assets/images/svg/implant.svg'
import arrow from '../../Assets/images/svg/arrowBack.svg'
import left from '../../Assets/images/svg/left.svg'
import right from '../../Assets/images/svg/right.svg'
import leftGray from '../../Assets/images/svg/leftGray.svg'
import rightGray from '../../Assets/images/svg/rightGray.svg'
import mask from '../../Assets/images/svg/mask.svg'
import queryString from 'query-string';
import { FREE_APPOINMENT_VARIANT_MEDELLIN, FREE_APPOINMENT_VARIANT_BUCARAMANGA, PROMO_APPOINMENT_VARIANT_MEDELLIN, PROMO_APPOINMENT_VARIANT_BUCARAMANGA } from '../../Utils/constants'
import { PageLoader } from '@mymoons/ui-library'
import countries from '../../Utils/countries'
import Middleware from '../../Api/Middleware'

import { setTrackingPatient } from '../../Utils/tracking'

/**
 * ChooseDates Component
 * @return {void}
 */
class ChooseDates extends React.Component {
  /**
   * Constructor
   * @param {*} props .
   */
  constructor(props) {
    super(props)

    let search = {}
    if(props && props.history.location && props.history.location.search){
      search = queryString.parse(props.history.location.search)
      if(search.state){
        delete search['state']
      }
    }
    props.history.push("?" + new URLSearchParams(search).toString())


    this.state = {
      dates: [],
      labelDates: {},
      loader: true,
      centerDate: 1,
      times: '',
      appointmentPrice: ''
    }
  }

  /**
   * Component Did Mount
   * @return {void}
   */
  componentDidMount = () => {
    this.calculateDates()
    this.fetchProductPrice()
    const { patient, isNewAppointment } = this.props
    let data = {
      distinct_id: patient.CustomerId,
      customer_id: patient.CustomerId,
      country_ops: patient.Country_Ops,
      public_key: patient.PublicKey,
      name: patient.Patient_Name,
      email: patient.Email,
      phone: patient.Phone,
      platform: 'appointment_platform_v3',
    };
    setTrackingPatient(patient,{name:`${isNewAppointment?'New':'Old'} Appointment Platform Time Slots -  Page Viewed`, data})
  }

  calculateDates = async () => {
    const { branch } = this.props
    const id = branch && branch.Appointment_Type_Id
    const appointment = await apiAcuity.appointmentTypes()
    let dates = []
    let nextDates = []

    const calendar = Array.isArray(appointment) && appointment.find(
      (ap) => ap.id.toString() === id
    )

    if (calendar) {
      const { month, nextMonth } = this.dateToAcuity()
      dates = await apiAcuity.dates(calendar.id, month, calendar.calendarIDs[0])
      if (dates.length < 15) {
        nextDates = await apiAcuity.dates(calendar.id, nextMonth, calendar.calendarIDs[0])
      }
      const { labelDates, newDates } = this.mapDates(dates, nextDates)
      const times = Array.isArray(newDates)
        && await Promise.all(newDates.map(date => this.getOneTime(date, calendar)))
      this.setState({
        dates: newDates, labelDates, times, loader: false
      })
    } else {
      this.setState({ loader: false })
    }
  }

  /**
   * Get One Time
   * @param {Array} date .
   * @param {Object} calendar .
   * @return {void}
   */
  getOneTime = async (date, calendar) => {
    const times = await apiAcuity.times(calendar.id, date, calendar.calendarIDs[0])
    return times
  }

  /**
   * Map Dates
   * @param {Array} dates .
   * @param {Array} nextDate .
   * @return {void}
   */
  mapDates = (dates, nextDate) => {
    const tempDate = dates.concat(nextDate)
    while (tempDate.length > 15) {
      tempDate.pop()
    }
    const labelDates = Array.isArray(tempDate) && tempDate.map(item => {
      const weekDay = moment(item.date).format('dddd')
      const date = moment(item.date).format('DD/MM')
      return [weekDay, date]
    })

    const newDates = Array.isArray(tempDate) && tempDate.map(item => item.date)
    return { newDates, labelDates }
  }

  dateToAcuity = () => {
    const month = moment().format('YYYY-MM')
    const nextMonth = moment().add(1, 'M').format('YYYY-MM')
    return { month, nextMonth }
  }

  fetchProductPrice = () => {
    const { country, patient } = this.props
    const countryCode = countries[country]
    Middleware.getTable(
      process.env.REACT_APP_MIDDLEWARE_ITEMS,
      'Product',
      null,
      {
        keyName:
          patient.Journey_Variant === "appointmentReducido"
            ? `appointment-${countryCode}-reducido`
            : `appointment-${countryCode}`,
      },
      this.successProductPrice,
      this.errorProductPrice
    )
  }

  successProductPrice = async (data) => {
    const { requirePayment } = this.props    
    if (data?.items?.length > 0) {
      const item = data.items[0]
      const price = requirePayment ? item.price : 0
      const finalPrice = requirePayment ? item.finalPrice : 0
      const discount = price - finalPrice

      const appointmentPrice = {
        currency: item.currency,
        price: Utils.formatCurrency(price, item.currency),
        finalPrice: Utils.formatCurrency(finalPrice, item.currency),
        discount: `${discount > 0 ? '-' : ''}${Utils.formatCurrency(discount, item.currency)}`,
      }
      this.setState({ appointmentPrice })
    }
  }

  errorProductPrice = (data) => {
    console.log(data)
  }

  itemChoose = () => {
    const { branch } = this.props
    const {
      Center_Name, Street, Number, Neighborhood
    } = branch
    const address = `${Street} ${Number}, ${Neighborhood}`

    return (
      <div
        className={styles.CenterContainer}
        key={branch.Appointment_Type_Id}
      >
        <div>
          {this.props.promoAppointment === FREE_APPOINMENT_VARIANT_MEDELLIN || 
          this.props.promoAppointment === FREE_APPOINMENT_VARIANT_BUCARAMANGA || 
          this.props.promoAppointment === PROMO_APPOINMENT_VARIANT_MEDELLIN || 
          this.props.promoAppointment === PROMO_APPOINMENT_VARIANT_BUCARAMANGA
            ?
            (
              <>
                <div className={`${styles.AddressContainer} ${styles.promoAppointmentContainer}`}>
                  <div className={styles.promoAppointmentTitleContainer}>
                    <p className={styles.Center}>{Center_Name}</p>
                    <div className={styles.promoAppointmentTextContainer}>
                      {(this.props.promoAppointment === FREE_APPOINMENT_VARIANT_MEDELLIN ||
                      this.props.promoAppointment === FREE_APPOINMENT_VARIANT_BUCARAMANGA) &&
                        this.props.counterPromo
                        ?
                        <>
                          <span/>
                          <p className={styles.promoAppointmentText}>CITA GRATIS</p>
                        </> :
                        <>
                          <span>   </span>
                          <p>PROMO CITA</p>
                        </>}
                    </div>
                  </div>
                  <p className={styles.Address}>{address}</p>
                </div>
              </> 
            )
            : (
              <div className={styles.AddressContainer}>
                <p className={styles.Center}>{Center_Name}</p>
                <p className={styles.Address}>{address}</p>
              </div>
            )}
        </div>
      </div>
    )
  }

  trackEvent(time) {
    const { selectTime, patient, branch, isNewAppointment } = this.props
    selectTime(time)
    let data = {
      distinct_id: patient.CustomerId,
      customer_id: patient.CustomerId,
      country_ops: patient.Country_Ops,
      public_key: patient.PublicKey,
      center_name: branch.Center_Name,
      platform:'appointment_platform_v3',
      appointment_date:time
    }
    setTrackingPatient(patient,{name:`${isNewAppointment?'New':'Old'} Appointment Platform - Day And Time Selected`, data})
  }

  DateColumns = () => {
    const { dates, times, centerDate, labelDates } = this.state
    const {  country, branch } = this.props
    const one = [moment().format('dddd'), moment().format('DD/MM')]
    let dateTwo = moment().add(1, 'days')
    let dateThree = moment().add(2, 'days')

    let currentDate = null
    
    if (Array.isArray(dates) && dates[centerDate - 1]) {
      currentDate = dates[centerDate - 1]
      dateTwo = moment(currentDate).add(1, 'days')
      dateThree = moment(currentDate).add(2, 'days')
    }
    if (Array.isArray(dates) && dates[centerDate]) {
      currentDate = dates[centerDate]
      dateThree = moment(currentDate).add(1, 'days')
    }
    const two = [dateTwo.format('dddd'), dateTwo.format('DD/MM')]
    const three = [dateThree.format('dddd'), dateThree.format('DD/MM')]

    const appointmentTypeId = branch && branch.Appointment_Type_Id ? branch.Appointment_Type_Id : false


  

    return (
      <div className={styles.DatesInnerContainer}>
        <div className={styles.DatesHeader}>
          <div className={styles.DayColumnHeader}>
            <p className={styles.DayLabel}>{labelDates[centerDate - 1] ? labelDates[centerDate - 1][0] : one[0]}</p>
            <p className={styles.DateLabel}>{labelDates[centerDate - 1]? labelDates[centerDate - 1][1] : one[1]}</p>
          </div>
          <div className={styles.DayColumnHeader}>
            <p className={styles.DayLabel}>{labelDates[centerDate] ? labelDates[centerDate][0] : two[0]}</p>
            <p className={styles.DateLabel}>{labelDates[centerDate] ? labelDates[centerDate][1] : two[1]}</p>
          </div>
          <div className={styles.DayColumnHeader}>
            <p className={styles.DayLabel}>{labelDates[centerDate + 1] ? labelDates[centerDate + 1][0] : three[0]}</p>
            <p className={styles.DateLabel}>{labelDates[centerDate + 1] ? labelDates[centerDate + 1][1] : three[1]}</p>
          </div>
        </div>
        <div className={styles.DayColumns}>
          <div className={styles.DayColumn}>
            {Array.isArray(times) && Array.isArray(times[centerDate - 1])
              && times[centerDate - 1].length > 0 ? times[centerDate - 1].map(item => {
                const time = Utils.getDateByCountryTimeZone(item.time, country, appointmentTypeId, 'h:mm a')
                return (
                  <div
                    role="button"
                    tabIndex={0}
                    onClick={() => this.trackEvent(item.time)}
                    key={item.time}
                    className={styles.AvailableTime}
                  >
                    {time}
                  </div>
                )
              }) : (
                <div className={styles.EmptyDate}>
                  <p>{messages.noDate}</p>
                </div>
              )}
          </div>
          <div className={styles.DayColumn}>
            {Array.isArray(times) && Array.isArray(times[centerDate])
            && times[centerDate].length > 0 ? times[centerDate].map(item => {
                const time = Utils.getDateByCountryTimeZone(item.time, country, appointmentTypeId, 'h:mm a')
                return (
                  <div
                    role="button"
                    tabIndex={0}
                    onClick={() => this.trackEvent(item.time)}
                    key={item.time}
                    className={styles.AvailableTime}
                  >
                    {time}
                  </div>
                )
              }) : (
                <div className={styles.EmptyDate}>
                  <p className={styles.EmptyDate}>
                    {messages.noDate}
                    <br/> 
                    {messages.noDateDescription}
                  </p>
                </div>
              )}
          </div>
          <div className={styles.DayColumn}>
            {Array.isArray(times) && Array.isArray(times[centerDate + 1])
            && times[centerDate + 1].length > 0
              ? times[centerDate + 1].map(item => {
                const time = Utils.getDateByCountryTimeZone(item.time, country, appointmentTypeId, 'h:mm a')
                return (
                  <div
                    role="button"
                    tabIndex={0}
                    onClick={() => this.trackEvent(item.time)}
                    key={item.time}
                    className={styles.AvailableTime}
                  >
                    {time}
                  </div>
                )
              }) : (
                <p className={styles.EmptyDate}>
                  {messages.noDate}
                  <br/> 
                  {messages.noDateDescription}
                </p>
              )}
          </div>
        </div>
      </div>
    )
  }

  /**
   * Change Date
   * @param {String} dir .
   * @return {void}
   */
  changeDate = (dir, moves) => {
    const { dates, centerDate } = this.state
    let isRight = dir === 'right'
    let movements = isRight ? 3 : -3
    

    function availableMoves() {
      if(!dates[centerDate + movements] && (centerDate + movements) >= 0 && (centerDate + movements) <= dates.length - 1) {
        isRight ? movements - 1 : movements + 1
        availableMoves()
      } else {
        return movements
      }
    }
    
    this.setState({ centerDate: centerDate + availableMoves() })
  }

  /**
   * Render
   * @returns {void}
   */
  render() {
    const { loader, dates, centerDate } = this.state
    const { name } = this.props
    const welcome = messages.welcome.replace('{Name}', name)
    let showLeft = !!dates[centerDate - 2]
    let showRight = !!dates[centerDate]

      if (!dates[centerDate - 2]) {
        showLeft = false
      }
      if (!dates[centerDate + 2]) {
        showRight = false
      }

    if (loader) {
      return (
        <div className={styles.ContainerLoader}>
          <PageLoader />
        </div>
      )
    }

    return (
      <div className={styles.ContainerDates}>
        {this.props.promoAppointment === FREE_APPOINMENT_VARIANT_MEDELLIN || this.props.promoAppointment === FREE_APPOINMENT_VARIANT_BUCARAMANGA || this.props.promoAppointment === PROMO_APPOINMENT_VARIANT_MEDELLIN || this.props.promoAppointment === PROMO_APPOINMENT_VARIANT_BUCARAMANGA}
        {this.itemChoose()}
        <p className={styles.Select}>{messages.select}</p>
        <div className={styles.DatesContainer}>
          <div className={styles.AllDates}>
            {showLeft ? (
            <button
              tabIndex={0}
              className={styles.ImageArrow}
              onClick={() => this.changeDate('left')}
              >
              
                <img
                  className={styles.imgButton}
                  alt="logo"
                  src={left}
                />
              </button>
              ) : (
                <div
                role="button"
                tabIndex={0}
                className={styles.ImageArrow}
                >
                  <img
                    className={`${styles.hidden}`}
                    alt="logo"
                    src={leftGray}
                  />
                </div>
              )}
           
            {this.DateColumns()}
            {showRight ? ( <button
              tabIndex={0}
              className={styles.ImageArrow}
              onClick={() => this.changeDate('right')}
              >
              
                <img
                  className={styles.imgButton}
                  alt="logo"
                  src={right}
                />
              </button>
              ) : (
                <div
                role="button"
                tabIndex={0}
                className={styles.ImageArrow}
                >
                  <img
                    className={`${styles.hidden}`}
                    alt="logo"
                    src={rightGray}
                  />
                </div>
              )}
            
          </div>
        </div>
      </div>
    )
  }
}

export default ChooseDates
