import React from 'react'
import PropTypes from 'prop-types'

import arrowBack from '../../Assets/images/svg/back.svg'
import styles from './HeaderBackButton.module.css'

/**
 * Go back button
 *
 * @param {object} props React Props
 * @param {func} onBack Function to execute when clicked
 * @returns {React.ReactElement} React Component
 */
const HeaderBackButton = ({ onBack }) => (
  <div role="button" tabIndex={0} className={styles.container} onClick={onBack}>
    <img alt="Flecha atrás" src={arrowBack} />
    <span className={styles.label}>Regresar</span>
  </div>
)

HeaderBackButton.propTypes = {
  onBack: PropTypes.func.isRequired
}

export default HeaderBackButton
