import PropTypes from "prop-types";
import Availability from "../Availability";
import styles from "./SmileCenterCard.module.css";

export const SmileCenterCard = ({
  centerName,
  address,
  country,
  smileCenterId,
  smileCentersAvailability,
  handleClick
}) => {
 

  return (
    <div
      className={styles.info}
      onClick={handleClick}
    >
      <div className={styles.details}>
        <div>
          <h1 className={styles.name}>{centerName}</h1>
          <p className={styles.description}>{address}</p>
        </div>
        <Availability
          country={country}
          smileCenterId={smileCenterId} 
          smileCentersAvailability={smileCentersAvailability}
        />
      </div>
    </div>
  );
};

SmileCenterCard.propTypes = {
  smileCenterId: PropTypes.string.isRequired,
  centerName: PropTypes.string.isRequired,
  address: PropTypes.string.isRequired,
  country: PropTypes.string.isRequired,
  handleClick: PropTypes.func.isRequired,
  smileCentersAvailability: PropTypes.array,
};

SmileCenterCard.defaultProps = {  
  smileCentersAvailability: undefined,
};
