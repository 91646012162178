/* eslint-disable quote-props */
/* eslint-disable import/prefer-default-export */
export const messages = {
  México: {
    modalFree: 'GRATIS',
    modalTitle: '¡Los alineadores Moons son una opción ideal para ti!',
    modalDescription: 'Agenda tu cita con uno de nuestros ortodoncistas para diseñar tu nueva sonrisa.',
    modalFreeDescription: 'Agenda tu cita gratis con uno de nuestros ortodoncistas para diseñar tu nueva sonrisa.',
    modalPrice: '$290 MXN',
    modalTextBefore: 'Antes: ',
    modalPriceBefore: '$990 MXN',
    modalDisclaimer: 'Reembolso del 100% en caso de que decidas no continuar'
  },
  Colombia: {
    modalFree: 'GRATIS',
    modalTitle: '¡Los alineadores Moons son una opción ideal para ti!',
    modalDescription: 'Agenda tu cita con uno de nuestros ortodoncistas para diseñar tu nueva sonrisa.',
    modalFreeDescription: 'Agenda tu cita gratis con uno de nuestros ortodoncistas para diseñar tu nueva sonrisa.',
    modalPrice: '$30.000 COP',
    modalTextBefore: 'Antes: ',
    modalPriceBefore: '$100.000 COP',
    modalDisclaimer: 'Reembolso del 100% en caso de que decidas no continuar'
  },
  Chile: {
    modalFree: 'GRATIS',
    modalTitle: '¡Los alineadores Moons son una opción ideal para ti!',
    modalDescription: 'Agenda tu cita con uno de nuestros ortodoncistas para diseñar tu nueva sonrisa.',
    modalFreeDescription: 'Agenda tu cita gratis con uno de nuestros ortodoncistas para diseñar tu nueva sonrisa.',
    modalPrice: '$5.900 CLP',
    modalTextBefore: 'Antes: ',
    modalPriceBefore: '$39.900 CLP',
    modalDisclaimer: 'Reembolso del 100% en caso de que decidas no continuar'
  },
  Peru: {
    modalFree: 'GRATIS',
    modalTitle: '¡Los alineadores Moons son una opción ideal para ti!',
    modalDescription: 'Agenda tu cita con uno de nuestros ortodoncistas para diseñar tu nueva sonrisa.',
    modalFreeDescription: 'Agenda tu cita gratis con uno de nuestros ortodoncistas para diseñar tu nueva sonrisa.',
    modalPrice: 'S/39',
    modalTextBefore: 'Antes: ',
    modalPriceBefore: 'S/79',
    modalDisclaimer: 'Reembolso del 100% en caso de que decidas no continuar'
  }
}

export const informativeModalMessages = {
  Peru: {
    modalTitle: '¡Los Moons son una opción ideal para ti!',
    modalSubTitle: 'Alinea tus dientes sin brackets desde S/ 196 por 12 meses.',
    modalDescriptionText1: 'Agenda tu cita de valoración por',
    modalPrice: ' S/39* ',
    modalDescriptionText2: 'con uno de nuestros ortodoncistas para diseñar tu nueva sonrisa.',
    AppointmentIncludeTitle: 'Cita incluye:',
    AppointmentIncludeStep1: 'Revisión completa',
    AppointmentIncludeStep2: 'Información de tratamiento',
    AppointmentIncludeStep3: 'Simulación 3D de tu sonrisa',
  }
}

export const variantsBySourceModalPrice = {
  México: {
    'appointment150': '$150 MXN',
    'appointment99': '$99 MXN',
    'mx_facebook99': '$99 MXN',
    'mx_facebook150': '$150 MXN',
    'mx_google150': '$150 MXN',
    'mx_google290': messages.México.modalPrice,
    'default': messages.México.modalPrice,
  },
  Colombia: {
    'appointmentReducido': '$19.900 COP',
    'co_facebook19900': '$19.900 COP',
    'co_facebook25000': '$25.000 COP',
    'co_google25000': '$25.000 COP',
    'co_google30000': messages.Colombia.modalPrice,
    'default': messages.Colombia.modalPrice,
  },
  Chile: {
    'cl_facebook5900': messages.Chile.modalPrice,
    'cl_facebook7000': '$7.000 CLP',
    'cl_google7000': '$7.000 CLP',
    'cl_google10900': '$10.900 CLP',
    'default': messages.Chile.modalPrice,
  },
  Peru: {
    'appointment19': 'S/19',
    'pe_facebook19': 'S/19',
    'pe_facebook29': 'S/29',
    'pe_google29': 'S/29',
    'pe_google39': messages.Peru.modalPrice,
    'default': messages.Peru.modalPrice,
  },

}
