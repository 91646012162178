const messages = {
  map: 'Ver en maps',
  thanks: 'Gracias por agendar, ya queremos verte!',
  addCalendar: 'Agregar a mi calendario',
  recommendation: 'Recomendaciones',
  seeMaps: 'Ver en Google Maps',
  newAppointment: 'Reagendar cita',
  cancelAppointment: 'Cancelar cita',
  backToUserDashboard: 'Ir a mi cuenta',
  appointmentVideocall: 'Agendar videollamada',
  title: 'Platica con un experto de sonrisa Moons',
  description: 'Agenda una videollamada con uno de nuestros asesores de sonrisa que te explicará a detalle todo lo que necesitas saber sobre el tratamiento, proceso y métodos de financiamiento antes de que acudas a tu cita.',
  congrats: 'Felicidades, {user}',
  appointmentReady: '¡Tu cita ya está agendada!',
  mailReady: '¡Todo listo! Tienes en tu correo la confirmación y también creamos un evento en tu calendario.',
  transactionNumber: 'No. de transacción: ',
  accountCTA: 'Ir a mi cuenta',
  detailsTitle: 'Tu cita',
  howToTitle: '¿Cómo llegar?',
  recomendations: {
    title: 'Durante tu cita',
    revision: { 
      title: 'Revisión de tus dientes',
      content: 'Tomamos unas fotos de tu rostro y tus dientes desde diversos ángulos y hacemos una revisión basada en tu quiz de candidato y formulario de previa cita.'
    },
    scan: {
      title: 'Escaneo 3D',
      content: 'Creamos una imagen 3D de tus dientes, que nos permite ver el estado actual y diseñar tu plan de tratamiento.'
    },
    payment: {
      title: 'Conoce tu diagnóstico y paga',
      content: 'En cuestión de minutos, nuestro equipo de profesionales te dirá si eres candidato a opción Lite o Full. Puedes pagar allí mismo y en aproximadamente una semana, te enviaremos la planeación de tu sonrisa para que conozcas cómo será el resultado final. '
    }
  }

}

export default messages
