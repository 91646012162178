/* eslint-disable import/extensions */
import React from 'react'
import { withRouter } from 'react-router-dom'
import Popup from 'reactjs-popup'
import { Experiment, Variant } from 'react-optimize'
import queryString from 'query-string';
import styles from './Branches.module.css'
import messages from './Branches.messages'
import Utils from '../../Utils/utils'
import countries from '../../Utils/countries'

import 'reactjs-popup/dist/index.css'
import './styles-pop-up.css'
import modalImage from '../../Assets/images/png/modal.png'

import Accordion from '../Accordion'
import AccordionHeader from '../AccordionHeader'
import AccordionContent from '../AccordionContent'
import SmileCenters from '../SmileCenters'
import SmileCenterCard from '../SmileCenterCard'
import { getSmileCenters } from '../../Api/SmileCenter'
import { setTrackingPatient } from '../../Utils/tracking'

/**
 * Branches Component
 * @return {React.Component} all smile centers
 */
class Branches extends React.Component {
  /**
   * Constructor
   * @param {*} props .
   */
  constructor(props) {
    super(props)

    let search = {}
    if(props && props.history.location && props.history.location.search){
      search = queryString.parse(props.history.location.search)
      if(search.state){
        delete search['state']
      }
    }
    props.history.push("?" + new URLSearchParams(search).toString())

    this.state = {
      branches: {},
      openedCollapsibles: {},
      smileCentersAvailability: null
    }
  }

  trackEvent(branch){
    const { setCurrentBranch, patient, isNewAppointment} = this.props
    setCurrentBranch(branch);
    let data = { 
      distinct_id: patient.CustomerId,
      customer_id: patient.CustomerId,
      country_ops: patient.Country_Ops,
      public_key: patient.PublicKey,
      center_name: branch.Center_Name,
      platform:'appointment_platform_v3'
    }
    setTrackingPatient(patient,{name:`${isNewAppointment?'New':'Old'} Appointment Platform - Center Selected`, data})
  }

  /**
   * Component Did Mount
   * @return {void}
   */
  componentDidMount = () => {
    const { items } = this.props
    items && this.mapBrachByState(items)
    window.gtag('event', 'optimize.callback', {
      name: 'lmO2jJwnRRKDDA88i8cKxw',
      callback: (value) => {
        if (value === '1') {
          window.freshpaint.track('MX - popUpTelemedicine', { variant: 'new' })
        } else {
          window.freshpaint.track('MX - popUpTelemedicine', { variant: 'original' })
        }
      },
    })
    this.getSmileCentersAvailability()
  }

  /**
   * Get smile centers by availability
   * @return {void}
   */
  getSmileCentersAvailability = () => {
    const { country } = this.props
    const countryCode = countries[country]
    const onSuccess = (data)=>{
      this.setState({ smileCentersAvailability: data })
    }
    const onError = (data)=>{
      this.setState({ smileCentersAvailability: [] })
      console.log(data)
    }
    getSmileCenters(countryCode, true, onSuccess, onError)
  };


  /**
   * Map Brach By State
   * @param {Object} items .
   * @return {void}
   */
  mapBrachByState = (items) => {
    const branches = {}    
    Array.isArray(items) && items.map((branch) => {
      if (branch.Active) {
        if (!branches[branch.State]) {
          branches[branch.State] = []
        }
        branches[branch.State].push(branch)
      }
      return ''
    })
    branches && this.setState({ branches, openedCollapsibles: Object.keys(branches).map(() => false ) })

  }

  /**
   * Item Component
   * @param {Object} key .
   * @param {Object} item .
   * @return {void}
   */
  itemComponent = (key, item, ind) => (
    <Accordion
      name={key}
      key={ind}
      index={ind}
      isOpen={this.state.openedCollapsibles[ind]}
      openOneCollapsibleOnly={this.openOneCollapsibleOnly}
      Header={(
        <AccordionHeader>
          <SmileCenters
            name={key}
            count={item.length}
          />
        </AccordionHeader>
      )}
      className={styles.ItemContainer}
    >
      <AccordionContent>
        {Array.isArray(item) && item.map((branch, index) => {
          const {
            Center_Name, Number, Neighborhood, Street, Prev_Videocall, objectId,
          } = branch
          const { publicKey, patient } = this.props
          const country = patient.Country_Ops;
          const address = `${Street} ${Number}, ${Neighborhood}`
          if (Prev_Videocall && Prev_Videocall === true && (country !== 'Colombia' || country !== 'colombia')) {
            return (
              <Experiment key={index} id="lmO2jJwnRRKDDA88i8cKxw">
                <Variant id="0">
                <SmileCenterCard
                  smileCenterId={objectId}
                  centerName={Center_Name}
                  address={address}
                  country={country}
                  smileCentersAvailability={this.state.smileCentersAvailability}
                  handleClick={() => this.trackEvent(branch)}
                /> 
                </Variant>
                <Variant id="1">
                  <Popup
                    onOpen={() => {
                      window.freshpaint.track('MX - popUpTelemedicine', { action: 'open' })
                    }}
                    trigger={(
                      <SmileCenterCard
                        key={index}
                        smileCenterId={objectId}
                        centerName={Center_Name}
                        address={address}
                        country={country}
                        smileCentersAvailability={this.state.smileCentersAvailability}
                        handleClick={() => setCurrentBranch(branch)}
                      />
                    )}
                    modal
                    nested
                    onClose={() => {
                      window.freshpaint.track('MX - popUpTelemedicine', { action: 'close' })
                      this.trackEvent(branch);
                    }}
                  >
                    {close => (
                      <div className="modal">
                        <div
                          className="close"
                          onClick={close}
                        >
                          &times;
                        </div>
                        <div className="content-wrapper">
                          <div className="modal-image">
                            <div>
                              <img
                                className="image"
                                src={modalImage}
                                alt="aligners"
                              />
                            </div>
                          </div>
                          <div className="content-texts">
                            <div className="header">
                              <p className="header-text">
                                {messages.modalTitle.one}
                                <br />
                                {messages.modalTitle.two}
                              </p>
                            </div>
                            <div className="content">
                              <p>
                                {messages.modalContent.one}
                                {/* replace centerName by the real name of the center */}
                                <span className="bold">{messages.modalContent.two.replace('centerName', Center_Name)}</span>
                                {messages.modalContent.three}
                              </p>
                            </div>
                            <div className="actions">
                              <div
                                className="cta"
                                onClick={() => {
                                  window.location.href = `https://precitas.mymoons.mx/${publicKey}`
                                }}
                              >
                                {messages.modalCTA}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </Popup>
                </Variant>
              </Experiment>
            )
          }
          return (
            <SmileCenterCard
              key={index}
              smileCenterId={objectId}
              centerName={Center_Name}
              address={address}
              country={country}
              smileCentersAvailability={this.state.smileCentersAvailability}
              handleClick={() => this.trackEvent(branch)}
            />
          );
        })}
        </AccordionContent> 
     </Accordion> 
  )

  openOneCollapsibleOnly = (index,key) => {
    const { branches } = this.state
    const { patient, isNewAppointment } = this.props
    this.setState({openedCollapsibles : Object.keys(branches).map((f, i) => i === index ? true : false)})
    let data = {
      distinct_id: patient.CustomerId,
      customer_id: patient.CustomerId,
      country_ops: patient.Country_Ops,
      public_key: patient.PublicKey,
      platform: 'appointment_platform_v3',
      city: key,
    };
    setTrackingPatient(patient,{name:`${isNewAppointment?'New':'Old'} Appointment Platform - City Selected`, data})
  }

  /**
   * Render
   * @returns {void}
   */
  render() {
    const { branches } = this.state
    const { name } = this.props

    const title = window.innerWidth < 700 ? messages.titleMobile : messages.titleDesktop
    const welcome = messages.welcome.replace('{Name}', name)

    return (
      <div className={styles.ContainerBranch}>
        <p className={styles.TitleName}>{welcome}</p>
        <p className={styles.Title}>{title}</p>
        <hr className={styles.Divider} />
        <div className={styles.BranchesList}>
          {branches && !Utils.isEmptyObject(branches)
          && Object.entries(branches).map(([key, value], index) => this.itemComponent(key, value, index))}
        </div>
      </div>
    )
  }
}

export default withRouter(Branches)
