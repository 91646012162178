import React from 'react'

import PropTypes from 'prop-types'
import styles from './AccordionHeader.module.css'
import arrow from '../../Assets/images/svg/arrow.svg'

/**
 * Accordion Header component
 *
 * @param {*} { children }
 * @returns {JSX.Element} Container
 */
export const AccordionHeader = ({ children }) => (
  <div>
    <div className={styles.accordionHeader}>
      {children}
      <img
        className={styles.chevron}
        alt="arrow"
        src={arrow}
      />
    </div>
    <hr className={styles.space} />
  </div>
)

AccordionHeader.propTypes = {
  children: PropTypes.node.isRequired
}
