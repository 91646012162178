import PropTypes from 'prop-types'
import styles from './AccordionContent.module.css'

export const AccordionContent = ({ children }) => (
  <>
  <div className={styles.accordionContent}>
    {children}
  </div>
  <hr />
  </>
)

AccordionContent.propTypes = {
  children: PropTypes.node.isRequired
}
