/* eslint-disable no-nested-ternary */
/* eslint-disable require-jsdoc */
export default class i18n {
  constructor(country) {
    this.countryCode = country === 'México' ? 'mx'
      : country === 'Colombia' ? 'co'
        : country === 'Chile' ? 'cl'
          : 'pe'
    const countries = {
      mx: {},
      co: {},
      cl: {},
      pe: {}
    }
    this.msg = countries[this.countryCode]
    // MX
    countries.mx.phone = {
      code: '+52',
      number: process.env.REACT_APP_WHATSAPP_MX
    }
    countries.mx.legal = {
      terms: 'https://legal.mymoons.mx/terminos-y-condiciones',
      privacy: 'https://legal.mymoons.mx/aviso-de-privacidad'
    }
    countries.mx.socialUrl = {
      whatsapp: `https://api.whatsapp.com/send?phone=${countries.mx.phone.code.slice(1)}${countries.mx.phone.number.replaceAll(' ', '')}&text=¡Hola, necesito más información!`,
      whatsappSupport: `https://api.whatsapp.com/send?phone=${countries.mx.phone.code.slice(1)}${countries.mx.phone.number.replaceAll(' ', '')}`,
      facebook: 'https://facebook.com/welovemoons',
      instagram: 'https://instagram.com/welovemoons/',
      youtube: 'https://youtube.com/channel/UCd7_nNxTedFFzxRVQRCg3cw'
    }

    // CO
    countries.co.phone = {
      code: '+57',
      number: process.env.REACT_APP_WHATSAPP_CO
    }
    countries.co.legal = {
      terms: 'https://legal.mymoons.co/terminos-y-condiciones',
      privacy: 'https://legal.mymoons.co/aviso-de-privacidad'
    }
    countries.co.socialUrl = {
      whatsapp: `https://api.whatsapp.com/send?phone=${countries.co.phone.code.slice(1)}${countries.co.phone.number.replaceAll(' ', '')}&text=¡Hola, necesito más información!`,
      whatsappSupport: `https://api.whatsapp.com/send?phone=${countries.co.phone.code.slice(1)}${countries.co.phone.number.replaceAll(' ', '')}`,
      facebook: 'https://facebook.com/welovemoons.co',
      instagram: 'https://instagram.com/welovemoons.co',
      youtube: 'https://youtube.com/welovemoons'
    }

    // CL
    countries.cl.phone = {
      code: '+56',
      number: process.env.REACT_APP_WHATSAPP_CL
    }
    countries.cl.legal = {
      terms: 'https://legal.mymoons.cl/terminos-y-condiciones',
      privacy: 'https://legal.mymoons.cl/aviso-de-privacidad'
    }
    countries.cl.socialUrl = {
      whatsapp: `https://api.whatsapp.com/send?phone=${countries.cl.phone.code.slice(1)}${countries.cl.phone.number.replaceAll(' ', '')}&text=¡Hola, necesito más información!`,
      whatsappSupport: `https://api.whatsapp.com/send?phone=${countries.cl.phone.code.slice(1)}${countries.cl.phone.number.replaceAll(' ', '')}`,
      facebook: 'https://facebook.com/welovemoons.cl',
      instagram: 'https://instagram.com/welovemoons.cl',
      youtube: 'https://youtube.com/welovemoons'
    }

    // PE
    countries.pe.phone = {
      code: '+51',
      number: process.env.REACT_APP_WHATSAPP_PE
    }
    countries.pe.legal = {
      terms: 'https://legal.moons.pe/terminos-y-condiciones',
      privacy: 'https://legal.moons.pe/aviso-de-privacidad'
    }
    countries.pe.socialUrl = {
      whatsapp: `https://api.whatsapp.com/send?phone=${countries.pe.phone.code.slice(1)}${countries.pe.phone.number.replaceAll(' ', '')}&text=¡Hola, necesito más información!`,
      whatsappSupport: `https://api.whatsapp.com/send?phone=${countries.pe.phone.code.slice(1)}${countries.pe.phone.number.replaceAll(' ', '')}`,
      facebook: 'https://facebook.com/welovemoons.pe',
      instagram: 'https://instagram.com/welovemoons.pe',
      youtube: 'https://youtube.com/welovemoons'
    }
  }

  countryCode

  msg
}
