import Collapsible from 'react-collapsible';
import PropTypes from 'prop-types';
import styles from './Accordion.module.css';

export const Accordion = ({
  index,
  Header,
  isOpen,
  openOneCollapsibleOnly,
  children,
  className,
  name
}) => (
  <div>
    <Collapsible
      contentOuterClassName={styles.card}
      triggerOpenedClassName={styles.open}
      open={isOpen}
      onTriggerOpening={() => openOneCollapsibleOnly(index,name)}
      trigger={Header}
      className={className}
    >
      {children}
    </Collapsible>
  </div>
)

Accordion.propTypes = {
  index: PropTypes.number.isRequired,
  Header: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
  openOneCollapsibleOnly: PropTypes.func,
  children: PropTypes.object,
  className: PropTypes.string
};

Accordion.defaultProps = {
  openOneCollapsibleOnly: () => {},
  children: {},
  className: ''
};
