/* eslint-disable complexity */
import { createRef } from 'react'
import currency from 'currency.js'
import moment from 'moment-timezone'

/**
 * Is param a empty object ?.
 * @param {string} obj .
 * @returns {void} .
 */
const isEmptyObject = (obj) => Object.keys(obj).length === 0 && obj.constructor === Object

/**
 * DynamicRef Function
 * @param {String} total .
 * @return {void}
 */
const dynamicRef = (total) => {
  const refs = []
  for (let initial = 0; initial < total; initial++) {
    refs[initial] = createRef()
  }
  return refs
}

/**
 * Get Style Item By Property
 * @param {String} node .
 * @param {String} property .
 * @return {void}
 */
const getStyleItemByProperty = (node, property) => {
  const nodeStyle = window.getComputedStyle(node)
  const styles = parseInt(nodeStyle.getPropertyValue(property), 10)
  return styles
}

/**
 * Get Date by TimeZone
 * @param {String} date .
 * @param {String} country .
 * @param {String} appointmentTypeId - Id of the type appointment
 * @param {String} timeFormat - Format date
 * @return {void}
 */
const getDateByCountryTimeZone = (date, country, appointmentTypeId = '', timeFormat = 'HH:mm') => {
  let time = moment(date).format(timeFormat)
  if (country === 'Colombia') {
    time = moment(date).tz('America/Bogota').format(timeFormat)
  } else if (country === 'Chile') {
    time = moment(date).tz('America/Santiago').format(timeFormat)
  } else if (country === 'Peru') {
    time = moment(date).tz('America/Lima').format(timeFormat)
  } else if (country === 'México') {
    if (appointmentTypeId && (appointmentTypeId === '10354067'
        || appointmentTypeId === '28885598'
        || appointmentTypeId === '30170549')
    ) {
      time = moment(date).tz('America/Tijuana').format(timeFormat)
    } 
    
    if (appointmentTypeId && (appointmentTypeId === '10353303' || appointmentTypeId === '28970034')) {
      time = moment(date).tz('America/Cancun').format(timeFormat)
    }
    
    if (appointmentTypeId && (appointmentTypeId === '22490364')) {
      time = moment(date).tz('America/Mazatlan').format(timeFormat)
    } else {
      time = moment(date).tz('America/Mexico_City').format(timeFormat)
    }
  }
  
  return time
}

/**
 * Get Discount
 * @param {String} countryCode .
 * @param {String} currencyProd .
 * @return {void}
 */
const getDiscount = (countryCode, currencyProd) => {
  let referralAmazon = `${currency(1000, { precision: 0 }).format()} ${currencyProd}`
  let referralDiscount = `${currency(1000, { precision: 0 }).format()} ${currencyProd}`
  switch (countryCode) {
  case 'co':
    referralAmazon = `${currency(169607, { precision: 0 }).format()} ${currencyProd}`
    referralDiscount = `${currency(169607, { precision: 0 }).format()} ${currencyProd}`
    break
  default:
    break
  }
  return { referralAmazon, referralDiscount }
}

/**
 * Format currency
 * @param {number} amount 
 * @param {string} currencyISO 
 * @returns {string}
 */
const formatCurrency = (amount, currencyISO) => {
  const options = {
    MXN: { separator: ',', decimal: '.', symbol: '$', precision: 0 },
    COP: { separator: '.', decimal: ',', symbol: '$', precision: 0 },
    CLP: { separator: '.', decimal: ',', symbol: '$', precision: 0 },
    PEN: { separator: ',', decimal: '.', symbol: 'S/ ', precision: 0 },
  }

  return currency(amount, options[currencyISO]).format()
}


/**
 * Choose Country Code
 * @param {String} country .
 * @returns {String} .
 */
const chooseCountryCode = (country) => {
  switch (country) {
  case 'Colombia':
    return 'co'
  default:
    return 'mx'
  }
}

/**
 * Parse timetable
 * @param {String} timetable .
 * @returns {String} .
 */
const parseTimeTable = (timetable) => {
  let timeResult = ''
  if (timetable.weekdays) {
    timeResult += `L-V ${timetable.weekdays.join(' ')}`
  }
  if (timetable.saturday) {
    timeResult += ` / S ${timetable.saturday.join(' ')}`
  }
  if (timetable.sunday) {
    timeResult += ` / D ${timetable.sunday.join(' ')}`
  }
  return timeResult
}

const getUserDashboardByCountry = (countryCode) => {
  const urls = {
    mx: process.env.REACT_APP_DASHBOARD_URL_MX,
    co: process.env.REACT_APP_DASHBOARD_URL_CO,
    cl: process.env.REACT_APP_DASHBOARD_URL_CL,
    pe: process.env.REACT_APP_DASHBOARD_URL_PE,
  }

  return urls[countryCode]
}


export default {
  parseTimeTable,
  chooseCountryCode,
  getDiscount,
  isEmptyObject,
  dynamicRef,
  getStyleItemByProperty,
  getDateByCountryTimeZone,
  formatCurrency,
  getUserDashboardByCountry
}
