import React from 'react'

import styles from './HeaderEmpty.module.css'

/**
 * Empty Space for header
 *
 * @returns {React.ReactElement} React Component
 */
const HeaderEmpty = () => (
  <div className={styles.empty} />
)

export default HeaderEmpty
