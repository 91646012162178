import React from 'react'
import PropTypes from 'prop-types'

import styles from './ProgressBar.module.css'

/**
 * Component to represent actions that take time or multi-step forms
 *
 * @param {object} props React Props
 * @param {number} progress Progress from 0 to 100
 * @returns {React.ReactElement} React Element
 */
const ProgressBar = ({ progress }) => (
  <div className={styles.container} role="progressbar" aria-valuemin={0} aria-valuemax={100} aria-valuenow={progress}>
    <div className={styles.bar} />
    <div className={styles.progress} style={{ width: `${progress}%` }} />
  </div>
)

ProgressBar.propTypes = {
  progress: PropTypes.number.isRequired,
}

export default ProgressBar
