import { callApi, createAuthHeaderEcomerce } from './Request'
import random from 'alphanumeric'

const auth_url = process.env.REACT_APP_BASIC_AUTH_ECOMERCE_TOOLS
const new_car_url = process.env.REACT_APP_BASIC_ECOMERCE_URL
const random_code = random(20);

export const auth = (success, error) => callApi(auth_url, {
    headers: createAuthHeaderEcomerce(),
    method: 'POST',
    
}, success, error)

export const new_car = (token, success, error, email) => callApi(new_car_url, {
    headers:{
        'x-api-key' : process.env.REACT_APP_BASIC_API_KEY_ECOMERCE
    },
    method: 'POST',
    body: JSON.stringify({
        "currency": "MXN",
        "key": `cart-key-${random_code}`,
        "customerEmail": email,
        "anonymousId": `anon-appointment-${random_code}`,
        "shippingAddress": {
            "country": "MX"
        },
        "country": "MX",
        "lineItems": [
            {
                "custom": {
                    "fields": {
                        "finalPrice":process.env.REACT_APP_NEW_CHECKOUT_FINAL_PRICE,
                        "productCategory": "appointment",
                        "productKeyCRM": "aligners-appointment-mx",
                        "relatedTreatment": {
                            "id": process.env.REACT_APP_NEW_CHECKOUT_RELATED_TREATMENT_ID,
                            "typeId": "product"
                        }
                    },
                    "type": {
                        "key": "lineItemType"
                    }
                },
                "quantity": 1,
                "sku": "appointment-mx",
                "productType": {
                    "typeId": "product",
                    "id": process.env.REACT_APP_NEW_CHECKOUT_STORE_ID,
                }
            }
        ],
        "origin": "Customer",
        "inventoryMode": "None",
  }),
}, success, error) 