import queryString from 'query-string'
import { callApi } from './Request'

const smileCenterUrl = process.env.REACT_APP_SMILE_CENTERS_API

export const getSmileCenters = (country, availability, success, error) => callApi(
  `${smileCenterUrl}?${queryString.stringify({
    country,
    availability,
  })}`,
  {
    method: 'GET',
  },
  success,
  error
)
