import React, { useRef, useState } from 'react'
import styles from './Menu.module.css'
import useOnClickOutside from '../../../hooks/useOnClickOutside'

function Menu({open, close, children}) {

    const ref = useRef()
    useOnClickOutside(ref, close)

    return (
    <section  ref={ref} className={styles.dropdown}>
        {open && children}
    </section>
  )
}

export default Menu