/* eslint-disable camelcase */
/* eslint-disable complexity */
import React from 'react'
import moment from 'moment-timezone'
import 'moment/locale/es'
import { Helmet } from 'react-helmet'
import styles from './Success.module.css'
import messages from './Success.messages'
import cosmo from '../../Assets/images/png/astro.png'
import cosmonaut from '../../Assets/images/svg/cosmonautBlue.svg'
import ok from '../../Assets/images/svg/ok.svg'
import implant from '../../Assets/images/svg/implant.svg'
import calendar from '../../Assets/images/svg/calendar.svg'
import infoIcon from '../../Assets/images/svg/infoIcon.svg'
import calendarCircle from '../../Assets/images/svg/calendar-circle.svg'
import mapa from '../../Assets/images/svg/mapa.svg'
import cog from '../../Assets/images/svg/cog.svg'
import arrow from '../../Assets/images/svg/arrow.svg'
import diagnostic from '../../Assets/images/svg/diagnostic.svg'
import scan from '../../Assets/images/svg/scan.svg'
import revision from '../../Assets/images/svg/revision.svg'
import mapPinCircle from '../../Assets/images/svg/map-pin-circle.svg'
// import Referral from '../Referral/Referral'
import Footer from '../Footer/Footer'
import FullModal from '../FullModal/FullModal'
import Confirm from '../Confirm/Confirm'
import Utils from '../../Utils/utils'
import queryString from 'query-string'
import { Button } from '@mymoons/ui-library'
import Middleware from '../../Api/Middleware'
import Video from './video/Video'
import Menu from './menu/Menu'

import { setTrackingPatient } from '../../Utils/tracking'

/**
 * Success Component
 * @return {void}
 */
class Success extends React.Component {
  /**
   * Constructor
   * @param {*} props .
   */
  constructor(props) {

    super(props)

    let search = {}
    let userDashboardReschedule
    if(props && props.history.location && props.history.location.search){
      search = queryString.parse(props.history.location.search)
      if(search.state){
        delete search['state']
      }
      if(search.reschedule){
        userDashboardReschedule = true
      }
    }
    props.history.push("?" + new URLSearchParams(search).toString())

    this.state = {
      showModal: false,
      showMap: true,
      imageMap: '',
      backToUserDashboardUrl: search.redirectUrl ? search.redirectUrl : undefined,
      userDashboardReschedule,
      showMenu: false
    }
  }

  /**
   * Component Did Mount
   * @return {void}
   */
  componentDidMount() {
    const { imageMap, userDashboardReschedule } = this.state
    const { branch, patient, isNewAppointment } = this.props
    let data = {
      distinct_id: patient.CustomerId,
      customer_id: patient.CustomerId,
      country_ops: patient.Country_Ops,
      public_key: patient.PublicKey,
      name: patient.Patient_Name,
      email: patient.Email,
      phone: patient.Phone,
      platform: 'appointment_platform_v3',
    };
    setTrackingPatient(patient,{name:`${isNewAppointment?'New':'Old'} Appointment Platform TYP -  Page Viewed`, data})
    if (!imageMap) {
      this.getImage(branch)
    }
    if (userDashboardReschedule === true) {
      this.showModal()
      if(this.props && this.props.history.location && this.props.history.location.search) {
        let search = queryString.parse(this.props.history.location.search)
        if(search.reschedule) {
          delete search['reschedule']
          this.props.history.push("?" + new URLSearchParams(search).toString())
        }
      }
    }
  }

  resume = () => {
    const {
      branch, dateSelected, userData, timeZone, transactionId
    } = this.props
    const {
      Center_Name, Street, Number, Neighborhood, Appointment_Type_Id, Center_Desc
    } = branch
    const { firstName, country } = userData

    const address = `${Street} ${Number}, ${Neighborhood}`
    const date = moment(dateSelected).format('dddd, DD MMMM')
    const appointmentStartTime = Utils.getDateByCountryTimeZone(dateSelected, country, Appointment_Type_Id)
    const appointmentEndTime = Utils.getDateByCountryTimeZone(moment(dateSelected).add(30, 'minutes'), country, Appointment_Type_Id)

    return (
      <div className={styles.CenterContainer}>
        <div
          className={styles.ItemSecond}
        >
          <img
            className={styles.Icon}
            alt="icon"
            src={mapPinCircle}
          />
          <div className={styles.AddressContainer}>
            <p className={styles.Center}>{Center_Name}</p>
            <p className={styles.itemSubtitle}>{address}</p>
          </div>
        </div>
        <div
          className={styles.ItemSecond}
        >
          <img
            className={styles.Icon}
            alt="icon"
            src={calendarCircle}
          />
          <div className={styles.InfoDate}>
            <p className={styles.itemSubtitle}>{date}</p>
            <p className={styles.itemSubtitle}>{appointmentStartTime} - {appointmentEndTime}</p>
          </div>
        </div>
        <div className={styles.howToSection}>
            <img src={infoIcon}/>
          <div>
            <h3>{messages.howToTitle}</h3>
            <p>{Center_Desc}</p>
          </div>
        </div>
      </div>
    )
  }

  

  buildCalendarLink = () => {
    const { appointment, branch } = this.props
    const {
      Zone, Street, Apt, Neighborhood, State, country, Zip
    } = branch
    const dateTime = appointment.datetime
    const timeStart = moment(dateTime).format('YYYYMMDDTHHmmss')
    const timeEnd = moment(dateTime)
      .add(appointment.duration, 'minutes')
      .format('YYYYMMDDTHHmmss')

    const text = appointment.type
    const location = `${Zone} - ${Street}, ${encodeURIComponent(branch.Number)}, 
    ${branch.Apt === '' ? '' : `${encodeURIComponent(Apt)},`} ${
      country === 'México' ? `${Neighborhood}, ${Zip},` : ''
    } ${State}`
    const details = `${location}%0A%0AVer/modificar cita:%0A${appointment.confirmationPage}`

    const link = `https://calendar.google.com/calendar/r/eventedit?dates=${timeStart}/${timeEnd}&text=${text}&location=${location}&details=${details}&sf=true`
    return link
  }

  showModal = () => {
    const { showModal } = this.state
    this.setState({ showModal: !showModal })
  }
  
  showMenu = (show) => {
    this.setState({ showMenu: !this.state.showMenu })
  }

  showCancelAppointmentModal = () => {
    const { showCancelAppointmentModal } = this.state
    this.setState({ showCancelAppointmentModal: !showCancelAppointmentModal })
  }

  backToUserDashboard = () => {
    const { backToUserDashboardUrl } = this.state
    window.open(backToUserDashboardUrl, '_self')
  }

  /**
   * Map Center.
   * @param {Object} branch .
   * @param {Object} error .
   * @returns {void} .
   */
  getImage = (branch, error) => {
    let imageMap = branch && branch.Map_Image
    const url = 'https://maps.googleapis.com/maps/api/staticmap'
    const zoom = 14
    let finalURL = `${url}?center=${branch.Latitude},${branch.Longitude}&zoom=${zoom}&scale=2&size=${400}x${250}&key=${process.env.REACT_APP_GOOGLE_API_KEY}`
    finalURL = `${finalURL}&markers=color:red|${branch.Latitude},${branch.Longitude}`
    imageMap = error ? branch && branch.Map_Image : finalURL
    this.setState({ imageMap })
  }

  /**
   * Render
   * @returns {void}
   */
  render() {
    const {
      appointment,
      branch,
      userData,
      dateSelected,
      cancelAppointment,
      nextStep,
      timeZone,
      setReason,
      customerId,
      publicKey
    } = this.props
    const { Notes, Prev_Videocall } = branch
    const { country } = userData
    const { showModal, showCancelAppointmentModal, showMap, imageMap, backToUserDashboardUrl} = this.state
    const linkCalendar = this.buildCalendarLink()
    const ConfirmationModalContent = (
      <Confirm
        type='reschedule'
        branch={branch}
        dateSelected={dateSelected}
        userData={userData}
        showModal={this.showModal}
        nextStep={nextStep}
        country={country}
        timeZone={timeZone}
        setReason={setReason}
        customerId={customerId}
        journeyVariant={this.props.journeyVariant}
      />
    )

    const CancelAppointmentModalContent = (
      <Confirm
        type='cancel'
        branch={branch}
        dateSelected={dateSelected}
        userData={userData}
        showModal={this.showCancelAppointmentModal}
        cancelAppointment={cancelAppointment}
        nextStep={nextStep}
        country={country}
        timeZone={timeZone}
        history= {this.props.history}
        setReason={setReason}
        customerId={customerId}
        publicKey= {publicKey}
        journeyVariant={this.props.journeyVariant}
      />
    )

    const showImageMap = branch && showMap

    return (
      <div className={styles.ContainerSuccess}>
         {country === 'Chile' && 
          <Helmet>
            <script type="text/javascript">
              {`!function(e,n){var s=n.createElement("script");s.type="text/javascript",s.crossOrigin="anonymous",s.async=!0,s.src="https://d2208bmkizb2br.cloudfront.net/conversion/moons-conversion.js";var t=n.getElementsByTagName("script")[0];t.parentNode.insertBefore(s,t)}(window,document);
              `}
            </script>
          </Helmet>
        }
         {country === 'México'
             && (
                 <>
                 <img src="https://secure.adnxs.com/px?id=1600493&seg=30511524&t=2" width="1" height="1" />
                 <img src="https://ad.doubleclick.net/ddm/activity/src=12152255;type=invmedia;cat=mymoo004;dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;npa=;gdpr=${GDPR};gdpr_consent=${GDPR_CONSENT_755};ord=1?" width="1" height="1" alt="" />
                 <img height="1" width="1" style={{ borderStyle: 'none' }} alt="" src="https://insight.adsrvr.org/track/pxl/?adv=424f6ds&ct=0:ydpsyid&fmt=3" />
                 </>
             )
       }
        <FullModal
          open={showModal}
          content={ConfirmationModalContent}
        />
        <FullModal
          open={showCancelAppointmentModal}
          content={CancelAppointmentModalContent}
        />
        {/* { (backToUserDashboardUrl && country === 'México' && Prev_Videocall)&& (
          <>
            <p className={styles.Title}>{messages.title}</p>
            <p className={styles.Description}>{messages.description}</p>
            <Button
              color='red'
              label={messages.appointmentVideocall}
              href={`${process.env.REACT_APP_VIDEOCALL_URL_MX}/${publicKey}?redirectUrl=${backToUserDashboardUrl}`}
              size="medium"
              variant='filled'
              className={styles.ButtonRed}
            />
          </>
        )} */}

        <div className={styles.headerContainer}>
          <div className={styles.header}>
            <p className={styles.headerUser}>{messages.congrats.replace('{user}', this.props.userData.firstName)}</p>
            <h1 className={styles.headerTitle}>{messages.appointmentReady}</h1>
            <p className={styles.headerMail}>{messages.mailReady}</p>
            {this.props.transactionId &&
              <div className={styles.headerTransaction}>
                <p>{messages.transactionNumber}</p>
                <p>{this.props.transactionId}</p>
              </div>
            }
            { backToUserDashboardUrl && (
              <Button
                id="new-appointment-btn-dashboard"
                color={(country === 'México' && Prev_Videocall) ? 'dark' : 'red'}
                label={messages.backToUserDashboard}
                onClick={() => this.backToUserDashboard()}
                size="medium"
                variant={'filled'}
                className={styles.ButtonRed}
              />
            )}
          </div>
        </div>
        <div className={styles.division}/>
        <div className={styles.appointmentDetails}>
          <div className={styles.detailsHeader}>
            <h2>
             {messages.detailsTitle} 
            </h2>
            <div onClick={()=> this.setState({showMenu: !this.state.showMenu})} aria-haspopup="menu" aria-controls="appointmentMenu" >
              <div>
                <img src={cog}/>
                <img src={arrow}/>
              </div>
            </div>
            <Menu open={this.state.showMenu} close={() => this.setState({showMenu: false})} id="appointmentMenu" role="menu">
              <div
                role="menuitem"
                tabIndex={0}
                onClick={() => this.showModal()}
              >
                {messages.newAppointment}
              </div>
              <div
                role="menuitem"
                tabIndex={-1}
                onClick={() => this.showCancelAppointmentModal()}
              >
                {messages.cancelAppointment}
              </div>
            </Menu>

          </div>
            <div className={styles.MapImagesContainer}>
            {showImageMap && (
              <div
                className={styles.MapContainer}
                role="button"
                tabIndex={0}
                onClick={() => branch.Map_URL && window.open(branch.Map_URL)}
              >
                <img
                  alt="map"
                  src={imageMap}
                  className={styles.MapImage}
                  onError={() => this.getImage(branch, true)}
                />
              </div>
            )}
          </div>
          <div className={[styles.Section, Notes ? '' : styles.Center].join(' ')}>
            <div className={styles.ResumeLink}>
              {this.resume()}
              <div className={styles.ContainerLinkCalendar}>
                {/* <a
                  href={linkCalendar}
                  className={styles.LinkCalendar}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  {messages.addCalendar}
                </a> */}
              </div>
            </div>
          </div>
          {branch && branch.Map_URL && (
              <div
                role="button"
                tabIndex={0}
                className={styles.SeeMaps}
                onClick={() => window.open(branch.Map_URL)}
              >
                <p>{messages.seeMaps}</p>
              </div>
            )}
        </div>
        <div className={styles.recomendations}>
          <h3>{messages.recomendations.title}</h3>
          {/* //TODO change video id */}
          <Video embedId={'aN0aAJC96sA'}/>
          <div>
            <img src={revision}/>
            <div>
              <h4>{messages.recomendations.revision.title}</h4>
              <p>{messages.recomendations.revision.content}</p>
            </div>
          </div>
          <div>
            <img src={scan}/>
            <div>
              <h4>{messages.recomendations.scan.title}</h4>
              <p>{messages.recomendations.scan.content}</p>
            </div>
          </div>
          <div className={styles.menu}>
            <img src={diagnostic}/>
            <div >
              <h4>{messages.recomendations.payment.title}</h4>
              <p>{messages.recomendations.payment.content}</p>
            </div>
          </div>

          
        </div>
      </div>
    )
  }
}

export default Success
