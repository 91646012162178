import React from 'react'
import './CandidateModal.css'
import { Button } from '@mymoons/ui-library'
import InformativeModalText from './InformativeModalText'
import centroMoonsMobile1x from '../../Assets/images/webp/CandidateModal/centroMoons-mobile@1x.webp'
import centroMoonsMobile2x from '../../Assets/images/webp/CandidateModal/centroMoons-mobile@2x.webp'
import centroMoonsMobile3x from '../../Assets/images/webp/CandidateModal/centroMoons-mobile@3x.webp'
import centroMoons1x from '../../Assets/images/webp/CandidateModal/centroMoons@1x.webp'
import centroMoons2x from '../../Assets/images/webp/CandidateModal/centroMoons@2x.webp'
import centroMoons3x from '../../Assets/images/webp/CandidateModal/centroMoons@3x.webp'
import useMediaQuery from '../../Utils/useMediaQuery'
import { messages, variantsBySourceModalPrice } from './messajes'

/**
 * CandidateModal Component
 * @returns {JSX.Element} CandidateModal Component
 */
const CandidateModal = ({ setModal, country, journey, isFree=false }) => {
  const {
    modalTitle,
    modalDescription,
    modalFreeDescription,
    modalPrice,
    modalTextBefore,
    modalPriceBefore,
    modalDisclaimer,
    modalFree
  } = messages[country] ? messages[country] : messages['México']
  const isMobile = useMediaQuery('(max-width: 700px)')
  const Journey_Variant = journey
  const showModalInformativoPerú = false;
  const priceByVariant = variantsBySourceModalPrice[country][Journey_Variant]

  const closeModal = () => {
    setModal(false)
    localStorage.setItem('hasModalBeenShown', JSON.stringify(true))
  }

  return (
    <div className="modal-container" onClick={closeModal}>
      <div onClick={e => e.stopPropagation()} className={showModalInformativoPerú ? 'modal-main_informative' : 'modal-main'}>
        {showModalInformativoPerú && isMobile ? null
          : (
            <img
              src={isMobile ? centroMoonsMobile1x : centroMoons1x}
              className="modal-img"
              width={312}
              height={170}
              srcSet={`${isMobile ? centroMoonsMobile2x : centroMoons2x} 2x, ${
                isMobile ? centroMoonsMobile3x : centroMoons3x
              } 3x`}
              alt="Centro Moons"
            />
          )}
        <div className={showModalInformativoPerú ? 'modal-content_informative' : 'modal-content'}>
          {showModalInformativoPerú ? <InformativeModalText />
            : (
              <>
                <h1 className="modal-title">{modalTitle}</h1>
                <p className="modal-description">
                  {isFree ? modalFreeDescription :modalDescription}
                </p>
                <p className="modal-price">
                    {isFree ? modalFree : Journey_Variant && priceByVariant
                    ? priceByVariant
                    : modalPrice}
                </p>
                <p className="modal-discount">
                  {modalTextBefore}
                  {' '}
                  <span>{modalPriceBefore}</span>
                </p>
              </>
            )
          }
          <Button
            color="red"
            fullWidth
            isButton
            label={isFree ?'Agendar cita gratis' : 'Agendar cita'}
           onClick={closeModal}
            size="medium"
            variant="filled"
          />
          {isFree ? null : <p className={showModalInformativoPerú ? 'modal-disclaimer_informative' : 'modal-disclaimer'}>
            {showModalInformativoPerú ? '*' : ''}
            {modalDisclaimer}
          </p>}
        </div>
      </div>
    </div>
  )
}

export default CandidateModal
