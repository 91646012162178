import PropTypes from 'prop-types'
import styles from './SmileCenters.module.css'
import messages from './messages'

export const SmileCenters = ({ name, count }) => {
  const countLabel =  `${count} ${count > 1
    ? messages.clinicAvailable.plural
    : messages.clinicAvailable.singular}`
  return (
    <div>
      <h1 className={styles.name}>{name}</h1>
      <p className={styles.count}>{countLabel}</p>
    </div>
  )
}

SmileCenters.propTypes = {
  name: PropTypes.string,
  count: PropTypes.number
};

SmileCenters.defaultProps = {
  name: '',
  count: 0
};
