import React from 'react'
import PropTypes from 'prop-types'

import moonsLogo from '../../Assets/images/svg/logo.svg'
import styles from './HeaderLogo.module.css'

/**
 * Logo with funcionality to go to the first step
 *
 * @param {object} props React Props
 * @param {func} onLogoClick Function to execute when the logo is clicked
 * @returns {React.ReactElement} React Component
 */
const HeaderLogo = ({ onLogoClick }) => (
  <div role="button" tabIndex={0} onClick={onLogoClick} className={styles.container}>
    <img className={styles.logoImg} alt="Moons" src={moonsLogo} />
  </div>
)

HeaderLogo.propTypes = {
  onLogoClick: PropTypes.func.isRequired
}

export default HeaderLogo
