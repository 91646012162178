import React, { useState, useEffect } from 'react'
import moment from 'moment'
import { Skeleton } from '@mymoons/ui-library'
import AvailabilityComponent from './AvailabilityComponent'
import Utils from '../../Utils/utils'

import styles from './Availability.module.css'

/**
 * Availability component
 *
 * @author Oscar Martinez <oscar.martinez@mymoons.mx>
 * version 1.0.0
 * date 2022-06-02
 * @param {*} {
 *   country,
 *   smileCenterId,
 * }
 * @returns {JSX.Element} Container
 */
const Availability = ({
  country,
  smileCenterId,
}) => {
  const [isLoading, setIsLoading] = useState(true)
  const [availabilityProps, setAvailabilityProps] = useState({
    availabilityType: 'default',
    text: null
  })
  
  /**
   * Get skeleton availability
   * @return {JSX.Element} component
   */
  const getSkeletonAvailability = () => (
    <div
      className={styles['availability-skeleton']}
    >
      <div className={styles.message}>
        <p className="caption">Cargando disponibilidad...</p>
      </div>
      <div
        className={styles['availability-container']}
        style={{ width: '100%' }}
      >
        <Skeleton
          style={{
            width: '100%',
            height: 16,
            borderRadius: 16,
          }}
          bottomLeftRadius="16"
          bottomRightRadius="16"
          topLeftRadius="16"
          topRightRadius="16"
          typography="body1"
          width="100%"
        />
      </div>
    </div>
  )

  /**
   * Build smile center availability component
   *
   * @param {object} element Smile center
   * @returns {JSX.Element} Smile centers availability component
   */
  const getLocationAvailability = () => {
    let availabilityType = "hight"
    let text = null
    setIsLoading(false)
    setAvailabilityProps({
      availabilityType,
      text
    })
  }

  useEffect(() => {
    getLocationAvailability()
  }, [])
  

  if (isLoading) {
    return getSkeletonAvailability()
  }

  return (
    <AvailabilityComponent {...availabilityProps} />
  )
}

export default Availability
