import React from 'react'
import PropTypes from 'prop-types'

import Header from '../Header'
import ProgressBar from '../ProgressBar/ProgressBar'
import Footer from '../Footer'
import styles from './Layout.module.css'

/**
 * Layout Component
 *
 * @param {object} props React Props
 * @param {React.ReactNode} children React Children
 * @param {number} progress Progress from 0 to 100
 * @param {string} country User Country
 * @param {func} onBack If no function is provided, the back button will not be rendered
 * @param {func} onLogoClick Funtion to execute when the logo is clicked
 * @return {JSX.Element} React element with children inside
 */
const Layout = ({
  children, progress, country, onBack, onLogoClick
}) => (
  <div className={styles.container}>
    <header className={styles.header}>
      {onBack ? <Header.Back onBack={onBack} /> : <Header.Empty />}
      <Header.Logo onLogoClick={onLogoClick} />
      <Header.Empty />
    </header>
    <ProgressBar progress={progress} />
    <main>
      {children}
    </main>
    <footer className={styles.stickyFooter}>
      {progress == 100&&(<Footer.Help country={country} />)}
      <Footer.Legal country={country} />
    </footer>
  </div>
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  progress: PropTypes.number.isRequired,
  country: PropTypes.string.isRequired,
  onBack: PropTypes.func,
  onLogoClick: PropTypes.func.isRequired
}

Layout.defaultProps = {
  onBack: null
}

export default Layout
