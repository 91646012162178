/* eslint-disable quote-props */
/* eslint-disable no-unused-vars */
export const CENTER_STEP = 'center'
export const DATE_STEP = 'date'
export const ACCEPT_STEP = 'accept'
export const SUCCESS_STEP = 'success'
export const CHECKOUT_STEP = 'checkout'
export const FREE_APPOINMENT_VARIANT_MEDELLIN = 'freeMedellin'
export const FREE_APPOINMENT_VARIANT_BUCARAMANGA = 'freeBucaramanga'
export const PROMO_APPOINMENT_VARIANT_MEDELLIN = 'promoMedellin'
export const PROMO_APPOINMENT_VARIANT_BUCARAMANGA = 'promoBucaramanga'

export const servicesList = [
  'limpieza',
  'carillas',
  'blanqueamiento',
  'implantes',
]

export const variantsBySource = {
  México: {
    'appointment150': 'cita150',
    'appointment99': 'cita99',
    'mx_facebook99': 'cita99',
    'mx_facebook150': 'cita150',
    'mx_google150': 'cita150',
    'mx_google290': 'cita',
    'default': 'cita',
  },
  Colombia: {
    'appointmentReducido': 'citaReducido',
    'co_facebook19900': 'citaReducido',
    'co_facebook25000': 'cita25000',
    'co_google25000': 'cita25000',
    'co_google30000': 'cita',
    'default': 'cita',
  },
  Chile: {
    'cl_facebook5900': 'cita',
    'cl_facebook7000': 'cita7000',
    'cl_google7000': 'cita7000',
    'cl_google10900': 'cita10900',
    'default': 'cita',
  },
  Peru: {
    'appointment19': 'cita19',
    'pe_facebook19': 'cita19',
    'pe_facebook29': 'cita29',
    'pe_google29': 'cita29',
    'pe_google39': 'cita',
    'default': 'cita',
  },

}
export const productsByVariant = {
  México: {
    'appointment150': 'appointment-mx-150',
    'appointment99': 'appointment-mx-99',
    'mx_facebook99': 'appointment-mx-99',
    'mx_facebook150': 'appointment-mx-150',
    'mx_google150': 'appointment-mx-150',
    'mx_google290': 'appointment-mx',
    'default': 'appointment-mx',
  },
  Colombia: {
    'appointmentReducido': 'appointment-co-reducido',
    'co_facebook19900': 'appointment-co-reducido',
    'co_facebook25000': 'appointment-co-25000',
    'co_google25000': 'appointment-co-25000',
    'co_google30000': 'appointment-co',
    'default': 'appointment-co',
  },
  Chile: {
    'cl_facebook5900': 'appointment-cl',
    'cl_facebook7000': 'appointment-cl-7000',
    'cl_google7000': 'appointment-cl-7000',
    'cl_google10900': 'appointment-cl-10900',
    'default': 'appointment-cl',
  },
  Peru: {
    'appointment19': 'appointment-pe-19',
    'pe_facebook19': 'appointment-pe-19',
    'pe_facebook29': 'appointment-pe-29',
    'pe_google29': 'appointment-pe-29',
    'pe_google39': 'appointment-pe',
    'default': 'appointment-pe',
  },
}
