import React from 'react'
import PropTypes from 'prop-types'

import styles from './FooterHelp.module.css'
import whatsapp from '../../Assets/images/svg/whatsapp.svg'
import I18n from '../../i18n'

/**
 * Footer help section to contact via whatsapp
 *
 * @param {object} props React props
 * @param {string} props.country User country
 * @returns {React.ReactElement} React Component
 */
const FooterHelp = ({ country }) => {
  const i18n = new I18n(country)

  return (
    <section className={styles.background}>
      <div className={styles.container}>
        <h3 className={styles.title}>¿Tienes dudas?</h3>
        <a  id="new-appointment-btn-whatsappSupport" className={styles.whatsappLink} href={i18n.msg.socialUrl.whatsapp} target="_blank" rel="noopener noreferrer">
          <img alt="Ícono de whatsapp" src={whatsapp} />
          <span>Chatea con nosotros</span>
        </a>
      </div>
    </section>
  )
}

FooterHelp.propTypes = {
  country: PropTypes.string.isRequired
}

export default FooterHelp
