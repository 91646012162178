import React from 'react'
import PropTypes from 'prop-types'
import styles from './Availability.module.css'

const Availabilities = {
  full: {
    background: '#BDBDBD',
    text: ' Sin citas disponibles',
  },
  hight: {
    background: '#05A357',
    text: 'Citas disponibles',
  },
  low: {
    background: '#FF6937',
    text: 'Citas disponibles',
  },
  default: {
    background: '#FF6937',
    text: 'Citas disponibles',
  },
}

/**
 * Availability component
 *
 * @author Oscar Martinez <oscar.martinez@mymoons.mx>
 * version 1.0.0
 * date 2022-06-02
 * @param {*} { availability, text }
 * @returns {JSX.Element} Container
 */
const AvailabilityComponent = ({ availabilityType, text }) => (
  <div
    className={`${styles['availability-container']} ${styles[availabilityType]}`}
  >
    <div
      className={styles.dot}
      style={{ background: Availabilities[availabilityType].background }}
    />
    <p>{text || Availabilities[availabilityType].text}</p>
  </div>
)

AvailabilityComponent.propTypes = {
  availabilityType: PropTypes.string.isRequired,
  text: PropTypes.string,
}
AvailabilityComponent.defaultProps = {
  text: '',
}

export default AvailabilityComponent
