import React from 'react'
import styles from './Video.module.css'

function Video({embedId}) {
  return (    
    <iframe
    id="new-appointment-video-youtube"
    className={styles.video}
    src={`https://www.youtube.com/embed/${embedId}?rel=0&showinfo=0&controls=0`}
    frameBorder="0"
    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
    allowFullScreen
    />
  )
}

export default Video