/* eslint-disable camelcase */
/* eslint-disable complexity */
import React from 'react'
import moment from 'moment'
import 'moment/locale/es'
import queryString from 'query-string'
import { Button, PageLoader } from '@mymoons/ui-library'
import styles from './Accept.module.css'
import messages from './Accept.messages'
import apiAcuity from '../../Api/Acuity'
import pinIcon from '../../Assets/images/svg/pin.svg'
import eventIcon from '../../Assets/images/svg/event.svg'
import arrow from '../../Assets/images/svg/arrowBack.svg'
import TimeCounter from '../TimeCounter/TimeCounter'
import Utils from '../../Utils/utils'
import countries from '../../Utils/countries'
import Middleware from '../../Api/Middleware'
import {
  FREE_APPOINMENT_VARIANT_MEDELLIN,
  FREE_APPOINMENT_VARIANT_BUCARAMANGA,
  PROMO_APPOINMENT_VARIANT_MEDELLIN,
  PROMO_APPOINMENT_VARIANT_BUCARAMANGA,
  productsByVariant
} from '../../Utils/constants'
import maintainUrlSearch from '../../Utils/maintain-url-search'
import { setTrackingPatient } from '../../Utils/tracking'
import { paramsObject } from '../../Utils/createUrlParams'

/**
 * Accept Component
 * @return {void}
 */
class Accept extends React.Component {
  /**
   * Constructor
   * @param {*} props .
   */
  constructor(props) {
    super(props)
    this.state = {
      expiredTime: false,
      loader: false,
      error: false,
      repeat: true,
      tries: 1,
      newAppointment: {},
      preloadStep: '',
      appointmentPrice: {},
      returnPolicyUrl: ''
    }
  }

  componentDidMount() {
    if (process.env.REACT_APP_ENVIRONMENT == 'PRODUCTION') window.fbq('track', 'SelectedAppointment')
    else console.log('avoid fb pixel')
    this.fetchProductPrice()
    this.setReturnPolicyUrl()
  }

  setReturnPolicyUrl = () => {
    const { country } = this.props

    const returnPolicyUrl = country === 'México' ? process.env.REACT_APP_RETURN_POLICY_URL_MX
      : country === 'Colombia' ? process.env.REACT_APP_RETURN_POLICY_URL_CO
        : country === 'Chile' ? process.env.REACT_APP_RETURN_POLICY_URL_CL
          : process.env.REACT_APP_RETURN_POLICY_URL_PE
    this.setState({ returnPolicyUrl })
  }

  fetchProductPrice = () => {
    const { patient } = this.props
    const { Country_Ops, Journey_Variant } = patient
    const productByVariant = productsByVariant[Country_Ops][Journey_Variant]
    Middleware.getTable(
      process.env.REACT_APP_MIDDLEWARE_ITEMS,
      'Product',
      null,
      {
        keyName:
        productByVariant || productsByVariant[Country_Ops].default
      },
      this.successProductPrice,
      this.errorProductPrice
    )
  }

  successProductPrice = async (data) => {
    const { requirePayment } = this.props
    if (data?.items?.length > 0) {
      const item = data.items[0]
      const price = requirePayment ? item.price : 0
      const finalPrice = requirePayment ? item.finalPrice : 0
      const discount = price - finalPrice

      const appointmentPrice = {
        currency: item.currency,
        price: Utils.formatCurrency(price, item.currency),
        finalPrice: Utils.formatCurrency(finalPrice, item.currency),
        discount: `${discount > 0 ? '-' : ''}${Utils.formatCurrency(discount, item.currency)}`,
      }
      this.setState({ appointmentPrice })
    }
  }

  errorProductPrice = (data) => {
    console.log(data)
  }

  backComponent = () => {
    const { nextStep } = this.props
    return (
      <div
        role="button"
        tabIndex={0}
        className={styles.Back}
        onClick={() => nextStep(true)}
      >
        <img
          alt="logo"
          src={arrow}
        />
        <p>{messages.back}</p>
      </div>
    )
  }

  branchChoose = () => {
    const { branch, dateSelected, country } = this.props
    const {
      Center_Name, Street, Number, Neighborhood, Appointment_Type_Id
    } = branch
    const address = `${Street} ${Number}, ${Neighborhood}`
    const date = moment(dateSelected).format('dddd DD MMMM')
    const time = Utils.getDateByCountryTimeZone(dateSelected, country, Appointment_Type_Id)
    const isDesktop = window.innerWidth > 700

    return (
      <div
        className={styles.CenterContainer}
        key={branch.Appointment_Type_Id}
      >
        <p className={styles.Subtitle}>{messages.description}</p>
        <div
          className={styles.Item}
        >
          <div
            className={styles.IconContainer}
          >
            <img
              className={styles.Icon}
              alt="icon"
              src={pinIcon}
            />
          </div>
          {this.props.promoAppointment === FREE_APPOINMENT_VARIANT_MEDELLIN || this.props.promoAppointment === FREE_APPOINMENT_VARIANT_BUCARAMANGA || this.props.promoAppointment === PROMO_APPOINMENT_VARIANT_MEDELLIN || this.props.promoAppointment === FREE_APPOINMENT_VARIANT_BUCARAMANGA
            ? (
              <>
                <div className={`${styles.AddressContainer} ${styles.promoAppointmentContainer}`}>
                  <div className={styles.promoAppointmentTitleContainer}>
                    <p className={styles.Center}>{Center_Name}</p>
                    <div className={styles.promoAppointmentTextContainer}>
                      {(this.props.promoAppointment === FREE_APPOINMENT_VARIANT_MEDELLIN || this.props.promoAppointment === FREE_APPOINMENT_VARIANT_BUCARAMANGA) && this.props.counterPromo
                        ? (
                          <>
                            <span />
                            <p className={styles.promoAppointmentText}>CITA GRATIS</p>
                          </>
                        )
                        : (
                          <>
                            <span>   </span>
                            <p>PROMO CITA</p>
                          </>
                        )}
                    </div>
                  </div>
                  <p className={styles.Address}>{address}</p>
                </div>

              </>
            )
            : (
              <div className={styles.AddressContainer}>
                <p className={styles.Center}>{Center_Name}</p>
                <p className={styles.Address}>{address}</p>
              </div>
            )
          }

          {/*  */}
        </div>
        <div
          className={styles.ItemSecond}
        >
          <div
            className={styles.IconContainer}
          >
            <img
              className={styles.Icon}
              alt="icon"
              src={eventIcon}
            />
          </div>
          <div className={styles.InfoDate}>
            <p className={styles.Dates}>{date}</p>
            <p className={styles.Address}>{time}</p>
          </div>
        </div>

        <hr className={styles.FooterDivider} />
        {isDesktop ? (
          <div className={styles.Footer}>
            <p className={styles.CostText}>{messages.appointmentCost}</p>
            <p className={styles.Price}>{this.state.appointmentPrice.finalPrice}</p>
          </div>
        ) : (
          <div className={styles.Footer}>
            <p className={styles.CostText}>{messages.appointmentCost}</p>
            <div className={styles.OriginalCostContainer}>
              <p className={styles.OriginalCostText}>$990</p>
              <p className={styles.Price}>{this.state.appointmentPrice.finalPrice}</p>
            </div>
          </div>
        )}

      </div>
    )
  }

  setExpired = () => {
    this.setState({ expiredTime: true })
  }

  checkDashboardStatus = async (callback) => {
    const { publicKey } = this.props
    const { repeat, tries } = this.state
    if (repeat && tries <= 4) {
      this.setPreloadStep(tries)
      setTimeout(() => {
        Middleware.getTable(process.env.REACT_APP_MIDDLEWARE_ITEM, 'Patient', null, { PublicKey: publicKey }, this.success, this.error)
      }, 500 * tries * 2)
    } else {
      this.goToNextStep()
    }
  }

  goToNextStep = () => {
    const { newAppointment } = this.state
    const { setAppointment } = this.props
    setAppointment(newAppointment)
  }

  setPreloadStep = (step) => {
    this.setState({ preloadStep: messages.preloadSteps[step] })
  }

  success = async (data) => {
    const { tries } = this.state
    const patient = data.Patient
    if (patient && patient.Dashboard_State) {
      const urlSearchParams = new URLSearchParams(window.location.search)
      const params = Object.fromEntries(urlSearchParams.entries())
      if (params.redirectUrl) {
        const url = new URL(params.redirectUrl)
        const urlSearchSubParams = new URLSearchParams(url.search)
        const subParams = Object.fromEntries(urlSearchSubParams.entries())
        if (subParams.state) {
          if (subParams.state === patient.Dashboard_State) {
            this.setState({ tries: tries + 1 })
            if (tries < 4) {
              await this.checkDashboardStatus()
            } else {
              this.goToNextStep()
            }
          } else {
            this.setState({ repeat: false })
            this.goToNextStep()
          }
        } else {
          this.goToNextStep()
        }
      }
    } else {
      this.goToNextStep()
    }
  }

  error = (data) => {
    console.log(data)
    this.setState({ repeat: false })
  }

  submitData = async () => {
    this.setState({ loader: true })
    const { expiredTime } = this.state
    const {
      dateSelected, branch, userData, setAppointment, customerId,
      publicKey, onsite, partner, requirePayment, crmAppointmentId,
      reason, cardHolder, cardCharged, isFree, country, isNewAppointment
    } = this.props
    const id = branch.Appointment_Type_Id
    if (expiredTime) {
      return ''
    }
    const journey = onsite || false
    const payment = isFree ? 'free_appointment' : cardHolder || cardCharged == 'Holded' ? 'card_holder' : requirePayment ? 'appointment' : false
    let rescheduleId
    let redirectUrl
    if (this.props && this.props.history.location && this.props.history.location.search) {
      const search = queryString.parse(this.props.history.location.search)
      rescheduleId = search.rescheduleId
      redirectUrl = search.redirectUrl
    }
    if (crmAppointmentId || rescheduleId) {
      await apiAcuity.update(rescheduleId || crmAppointmentId, { fields: [{ id: process.env.REACT_APP_RESCHEDULING_FIELD_ID, value: 'rescheduling' }] })
      const queryParams = paramsObject()
      const { utm_source: utmSource } = queryParams
      await apiAcuity.cancel(rescheduleId || crmAppointmentId, undefined, utmSource)
    } else if (requirePayment) {
      const data = {
        distinct_id: customerId,
        customer_id: customerId,
        country_ops: country,
        public_key: publicKey,
        platform: 'appointment_platform_v3'
      }

      setTrackingPatient({ CustomerId: customerId }, { name: `${isNewAppointment ? 'New' : 'Old'} Appointment Platform - Appointment Prescheduled`, data })
    } else if (window.freshpaint) {
      window.freshpaint?.track('confirm_appointment_old_ap', {
        customerId,
        publicKey,
        distinct_id: customerId,
        customer_id: customerId,
        country_ops: country,
        platform: 'appointment_platform_v3'
      })
    }

    const appointment = await apiAcuity.appointments(
      dateSelected,
      id,
      userData,
      customerId,
      publicKey,
      partner,
      journey,
      payment,
      reason
    )
    if (appointment && appointment.id) {
      let history = `${maintainUrlSearch()}`
      history += `&rescheduleId=${appointment.id}`
      if (!requirePayment && this.props.counterPromo) {
        setAppointment && setAppointment(appointment)
        return
      }
      this.setState({ newAppointment: appointment })
      if (redirectUrl) {
        // history += `&redirectUrl=${encodeURIComponent(redirectUrl)}`
        if (!requirePayment) {
          const url = new URL(redirectUrl)
          if (url) {
            const urlSearchSubParams = new URLSearchParams(url.search)
            const subParams = Object.fromEntries(urlSearchSubParams.entries())
            if (subParams.state) {
              this.props.history.push(history)
              this.setPreloadStep(0)
              this.checkDashboardStatus()
            } else {
              setAppointment && setAppointment(appointment)
              this.props.history.push(history)
            }
          }
        } else {
          this.goToNextStep()
        }
      } else {
        this.props.history.push(history)
        setAppointment && setAppointment(appointment)
      }
    } else {
      this.setState({ error: messages.error, loader: false })
    }
  }

  returnPopup = () => {
    const { nextStep } = this.props
    nextStep(true)
  }

  /**
   * Render
   * @returns {void}
   */
  render() {
    const isDesktop = window.innerWidth > 700
    const {
      expiredTime, loader, error, preloadStep
    } = this.state
    const {
      userData, requirePayment, crmAppointmentId, cardHolder, isFree
    } = this.props
    const { firstName } = userData
    const welcome = messages.welcome.replace('{Name}', firstName)
    let appointmentId
    if (crmAppointmentId) {
      appointmentId = crmAppointmentId
    } else if (this.props && this.props.history.location && this.props.history.location.search) {
      const search = queryString.parse(this.props.history.location.search)
      if (search.rescheduleId) {
        appointmentId = search.rescheduleId
      }
    }

    const isCitaPromoFlow = this.props.promoAppointment === FREE_APPOINMENT_VARIANT_MEDELLIN
      || this.props.promoAppointment === FREE_APPOINMENT_VARIANT_BUCARAMANGA
      || this.props.promoAppointment === PROMO_APPOINMENT_VARIANT_MEDELLIN
      || this.props.promoAppointment === PROMO_APPOINMENT_VARIANT_BUCARAMANGA

    return (
      <div className={styles.ContainerAccept}>
        <div>
          {/* {isCitaPromoFlow ? null : this.backComponent()} */}
          <TimeCounter
            setExpired={this.setExpired}
            clearPopup={this.returnPopup}
            promoAppointment={this.props.promoAppointment}
            counterPromo={this.props.counterPromo}
            syncMinutes={this.props.syncMinutes}
            syncSeconds={this.props.syncSeconds}
          />
          <p className={styles.TitleName}>{messages.title}</p>
          {this.branchChoose()}

          {loader
            ? (
              <div className={styles.ContainerLoader}>
                <p className={styles.PreloadStep}>{preloadStep}</p>
                <PageLoader />
              </div>
            ) : !expiredTime
            && (
              <>
                {isCitaPromoFlow
                  ? (
                    <Button
                      color="red"
                      fullWidth
                      isButton
                      label={this.props.promoAppointment === PROMO_APPOINMENT_VARIANT_MEDELLIN || this.props.promoAppointment === PROMO_APPOINMENT_VARIANT_BUCARAMANGA || requirePayment || !this.props.counterPromo ? 'Agendar cita' : 'Agendar cita gratis'}
                      onClick={() => this.submitData()}
                      size="medium"
                      variant="filled"
                      className={styles.SubmitButton}
                    />
                  )
                  : (
                    <Button
                      id="new-appointment-submit-pay"
                      color="red"
                      fullWidth
                      isButton
                      label={isFree ? messages.confirmAppointmentFree : cardHolder ? messages.freeAppointment : requirePayment ? messages.payAppointment.replace('{price}', this.state.appointmentPrice?.finalPrice)
                        : appointmentId
                          ? messages.rescheduleAppointment
                          : messages.confirmAppointment
                      }
                      onClick={() => this.submitData()}
                      size="medium"
                      variant="filled"
                      className={styles.SubmitButton}
                    />
                  )
                }
                <p className={styles.Error}>{error}</p>
              </>
            )
          }
        </div>

        {isDesktop && (
          <>
            <div className={styles.PriceDetailsDivider}>
              <hr />
            </div>
            <div className={styles.PriceDetailsContainer}>
              <p>{messages.priceDetail}</p>
              <div>
                <p>{messages.appointmentCost}</p>
                <p>{this.state.appointmentPrice?.price}</p>

              </div>
              {requirePayment && (
                <>
                  <hr />
                  <div>
                    <p>{messages.promoDiscount}</p>
                    <p className={styles.Discount}>{this.state.appointmentPrice?.discount}</p>
                  </div>
                </>
              )}
              <hr className={styles.TotalDivider} />
              <div className={styles.Total}>
                <p>{messages.total.replace('{currency}', this.state.appointmentPrice?.currency)}</p>
                <p>{this.state.appointmentPrice?.finalPrice}</p>
              </div>
            </div>
          </>
        )}
      </div>
    )
  }
}

export default Accept
